<template>
    <div class="sessions-list-wrapper">
        <h3 class="sidebar-title">My Sessions ({{ total_user_sessions }})</h3>
        <div v-if="total_user_sessions <= 0" class="filmstro-message filmstro-warning" style="text-align: center;">
            You have not saved any sessions yet.
        </div>
        <div tag="div" name="fade" class="session-list">
            <div class="session" v-for="session in user_sessions" :key="session.session_id"
                @click.self.stop.prevent="openSession( session )"
                :class="{ 'show-actions': show_actions_for == session.session_id, 'active': current_session && current_session.session_id == session.session_id }">
                <div class="session-content">
                    <!-- <div class="session-image"> <img loading="lazy" :src="session.cover_image" alt=""> </div> -->
                    <div class="session-data">
                        <h3 class="session-title"><span>{{ session.title }}{{ session.is_v3 ? ' (V3)' : '' }}</span>
                            <span class="session-theme">{{ session.theme }}</span>
                        </h3>
                        <div class="session-actions-wrapper">
                        <button class="clear-button arename-session-button" @click="openSession(session)">
                            <filmstro-icon name="pen" />
                        </button>
                        <button class="clear-button rename-session-button" @click="maybeRenameSession(session)">
                            <filmstro-icon name="rename" />
                        </button>
                        <button class="clear-button rename-session-button" @click="maybeDuplicateSession(session)">
                            <filmstro-icon name="duplicate-icon" />
                        </button>
                        <button class="clear-button delete-session-button" @click="maybeDeleteSession(session)">
                            <filmstro-icon name="delete-icon" />
                        </button>
                        <!-- <button class="clear-button session-actions-close" @click="show_actions_for = false">
                            <filmstro-icon name="close-icon" />
                        </button> -->
                    </div>
                    </div>

                </div>
                <!-- <div class="session-options">
                    <button class="clear-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 25 4.03125 C 24.234375 4.03125 23.484375 4.328125 22.90625 4.90625 L 13 14.78125 L 12.78125 15 L 12.71875 15.3125 L 12.03125 18.8125 L 11.71875 20.28125 L 13.1875 19.96875 L 16.6875 19.28125 L 17 19.21875 L 17.21875 19 L 27.09375 9.09375 C 28.246094 7.941406 28.246094 6.058594 27.09375 4.90625 C 26.515625 4.328125 25.765625 4.03125 25 4.03125 Z M 25 5.96875 C 25.234375 5.96875 25.464844 6.089844 25.6875 6.3125 C 26.132813 6.757813 26.132813 7.242188 25.6875 7.6875 L 16 17.375 L 14.28125 17.71875 L 14.625 16 L 24.3125 6.3125 C 24.535156 6.089844 24.765625 5.96875 25 5.96875 Z M 4 8 L 4 28 L 24 28 L 24 14.8125 L 22 16.8125 L 22 26 L 6 26 L 6 10 L 15.1875 10 L 17.1875 8 Z"/></svg>
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</template>


<script>
import { calculateHeightForElement } from '@/includes/helpers';
import { mapActions, mapGetters } from 'vuex';
import FavouriteToggle from '../components/FavouriteToggle.vue'
export default {
    components: {
        FavouriteToggle
    },
    data() {
        return {
            show_actions_for: "a45ce23386084eb2c325a370"
        }
    },
    methods: {
        ...mapActions(['fetchUserSessions', 'renameSession', 'deleteSession','duplicateSession']),
        async maybeDuplicateSession(session) {
            this.$store.commit('setShowFlyoutSideBar', false);
            let $this = this;
            let { value: session_name } = await this.$swal.fire({
                title: 'Duplicate Session ?',
                input: 'text',
                inputLabel: 'Duplicate as',
                inputValue: session.title,
                allowOutsideClick: false,
                confirmButtonText: "Duplicate",
                showCancelButton: true,
                customClass: {
                    container: 'filmstro-modal-with-logo'
                },
                iconHtml: '<img loading="lazy"  class="filmstro-icon-logo" src="https://filmstro.com/wp-content/uploads/2022/03/filmstro-logo.png" >',
                inputValidator: (value) => {
                    if (!value) {
                        return 'You need to write something!'
                    }
                }
            });

            if (session_name && session_name !== session.title) {
                let data = {
                    session_id: session.session_id,
                    title: session_name
                }
                this.duplicateSession(data).then(response => {
                    if (response.status == 'success') {
                        this.$swal({
                            title: 'Duplicated!',
                            text: `Session was duplicated to ${session_name}`
                        });
                    } else {
                        this.$swal.fire({
                            title: 'error!',
                            type: 'error',
                            text: `Someething went wrong while duplicating to ${session_name} from ${session.title}`
                        });
                    }

                    $this.$store.dispatch('fetchUserSessions', true);
                    $this.show_actions_for = false;
                })
            }

        },
        async maybeRenameSession(session) {
            this.$store.commit('setShowFlyoutSideBar', false);
            let $this = this;
            let { value: session_name } = await this.$swal.fire({
                title: 'Rename Session Name',
                input: 'text',
                inputLabel: 'Session name',
                inputValue: session.title,
                allowOutsideClick: false,
                confirmButtonText: "Rename",
                showCancelButton: true,
                customClass: {
                    container: 'filmstro-modal-with-logo'
                },
                iconHtml: '<img loading="lazy"  class="filmstro-icon-logo" src="https://filmstro.com/wp-content/uploads/2022/03/filmstro-logo.png" >',
                inputValidator: (value) => {
                    if (!value) {
                        return 'You need to write something!'
                    }
                }
            });

            if (session_name && session_name !== session.title) {
                let data = {
                    session_id: session.session_id,
                    title: session_name
                }
                this.renameSession(data).then(response => {
                    if (response.status == 'success') {
                        this.$swal({
                            title: 'Renamed!',
                            text: `Session was renamed to ${session_name} from ${session.title}`
                        });
                    } else {
                        this.$swal.fire({
                            title: 'error!',
                            type: 'error',
                            text: `Someething went wrong while renaming to ${session_name} from ${session.title}`
                        });
                    }

                    $this.$store.dispatch('fetchUserSessions', true);
                    $this.show_actions_for = false;
                })
            }

        },
        async maybeDeleteSession(session) {
            this.$store.commit('setShowFlyoutSideBar', false);
            let $this = this;
            await this.$swal.fire({
                title: 'Your file will be deleted',
                text: "Deleted files cannot be recovered",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                showLoaderOnConfirm: true,
                preConfirm: async (confirm) => {
                    let _data = {
                        session_id: session.session_id,
                        user_id: $this.$store.getters.LOGGED_IN_USER_ID
                    }
                    if (confirm) {
                        $this.deleteSession(_data).then(d => {
                            $this.$store.dispatch('fetchUserSessions', true);
                        })
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {

                if (result.isConfirmed) {
                    // $this.$notification.success("Deleted","Session Deleted");
                }
            })
        },
        toggleActions(session) {
            if (this.show_actions_for == session.session_id) {
                this.show_actions_for = false;
            } else {
                this.show_actions_for = session.session_id
            }
        },
        async openSession(session) {
            if (this.current_session && this.current_session.session_id === session.session_id) {
                this.$store.commit('setShowFlyoutSideBar', false);
                return;
            }

            this.$store.commit('setShowFlyoutSideBar', false);

            if (!session.is_v3 || typeof session.is_v3 == 'undefined') {
                await this.$swal.fire({
                    title: false,
                    html: `It looks like <strong>${session.title}</strong> was created with Version 2 of Filmstro App. Please open this session in V2.`,
                    type: 'warning',
                    showCancelButton: true,
                }).then(r => {
                    if (r.isConfirmed) {
                        window.open(`https://v2.filmstro.com/edit/${session.theme}/main/${session.session_id}`, '_blank');
                    }
                });
                return;
            }

            if (this.current_session && this.current_session.session_id !== session.session_id) {
                let a = await this.$swal.fire({
                    title: 'Switch Sessions',
                    html: `Are you sure you want to switch to <strong>${session.title}</strong> ? You are currently working on <strong>${this.current_session.title}</strong>`,
                    allowOutsideClick: false,
                    confirmButtonText: "Yes",
                    showCancelButton: true,
                    customClass: {
                        container: 'filmstro-modal-with-logo'
                    },
                });
                if (a && !a.isConfirmed) {
                    return;
                }
            }

            this.$store.commit('setShowLoader', true);
            this.$store.commit('setLoaderMessage', 'Loading Session');
            this.$store.dispatch('edit/resetState');
            this.$store.commit('setShowFlyoutSideBar', false);
            this.$store.commit('edit/setCurrentEditView', 'presets');

            let a = this.$router.resolve({
                name: 'edit',
                params: {
                    theme: session.theme,
                    version: "main",
                    session_id: session.session_id
                },
                query: {
                    template: session.template
                }
            })
            location.href = a.href
            // location.reload();


        }
    },
    computed: {
        ...mapGetters(['user_sessions', 'CURRENT_USER']),
        ...mapGetters('edit', ['current_session']),
        // ...mapGetters('auth',['CURRENT_USER']),
        total_user_sessions() {
            if (this.user_sessions) {
                return Object.keys(this.user_sessions).length
            } else {
                return 0;
            }
        }
    },
    async mounted() {
        let a = calculateHeightForElement('.session-list', '.sessions-list-wrapper');
        if (this.CURRENT_USER && !this.CURRENT_USER.user_id) {
            return;
        }
        // this.$store.commit('setShowLoader',true);
        // this.$store.commit('setLoaderMessage','Loading collections...','Browse.vue created()')
        await this.fetchUserSessions().then(d => {
            this.$store.commit('setShowLoader', false);
        })
    }
}
</script>



<style scoped lang="scss">
.template-data.main-width {
    // height: 380px;
}

.sessions-list-wrapper {
    height: 100%;
    z-index: 1;
    position: relative;
    padding: 10px 20px;
}

.session-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
    max-height: 770px;
    overflow-y: scroll;
    padding-right: 10px;
}

.session-content {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

.session {
    display: flex;
    height: 65;
    border: solid;
    width: 100%;
    background: #21222B;
    border: var(--filmstro-border);
    border-radius: 12px;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:hover{
        border-color: var(--filmstro-yellow);
    }
}

.session-image {
    width: 65px;
    height: 65px;
}

.session-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // border-top-left-radius:12px;
    // border-bottom-left-radius:12px;
}

.session-data {
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}



h3.session-title {
    color: white;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    gap: 5px;
}
.session-theme{
    color: var(--filmstro-yellow);
}
.session-actions-wrapper {
    margin-left: auto;
    height: 100%;
    padding: 10px;
    // width: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5px;

    button {
        border-radius: 3px;
        padding: 5px;

        &:hover {
            background-color: var(--filmstro-gray);
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
}



.session-options {
    display: flex;
    flex: 0;
    display: none;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.session-options img,
.session-options svg {
    width: 22px;
    height: 22px;
}



.session-actions {
    /* position: relative; */
}

.session-actions-dropdown {
    position: absolute;
    top: 0;
    left: -300px;
    background: #4a4a4a;
    height: 100%;
    padding: 0px 5px;
    border-radius: 4px;
    min-width: 100px;
    display: flex;
    transition: all .4s ease;
}

.session-actions-dropdown button {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 3px;
    color: white;
}

.session-actions-dropdown button:hover {
    opacity: .9;
    color: var(--filmstro-yellow);

    svg {
        fill: var(--filmstro-yellow);
    }
}

.session-actions-dropdown img {
    width: 22px;
    height: 22px;
}

.session.active {
    border-color: var(--filmstro-yellow);
}

.session.show-actions .session-actions-dropdown {
    display: flex;
    justify-content: space-between;
    width: 100%;
    left: 0;
}

.session.show-actions .session-options {
    display: flex;
}

.session-actions-toggle {
    height: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 20px;
        height: 20px;
    }

    &:hover {
        background: var(--filmstro-gray);
        border: solid 1px white;
        border-radius: 4px;
    }
}

button.session-actions-close {
    width: 50px;
    border-left: var(--filmstro-border);
    margin-bottom: 0 !important;
    padding: 10px;

}
</style>