<template>
    <div class="keyboard-wrapper">
        <div class="keyboards" >
            <div ref="keyboard" class="keyboard" :class="{'is_pressing_key':is_pressing_key}">
                <div class="keyboard__row keyboard__row--h1">
                    <div data-key="27" class="key--word">
                        <span>esc</span>
                    </div>
                    <div data-key="112" class="key--fn">
                        <span>F1</span>
                    </div>
                    <div data-key="113" class="key--fn">
                        <span>F2</span>
                    </div>
                    <div data-key="114" class="key--fn">
                        <span>F3</span>
                    </div>
                    <div data-key="115" class="key--fn">
                        <span>F4</span>
                    </div>
                    <div data-key="116" class="key--fn">
                        <span>F5</span>
                    </div>
                    <div data-key="117" class="key--fn">
                        <span>F6</span>
                    </div>
                    <div data-key="118" class="key--fn">
                        <span>F7</span>
                    </div>
                    <div data-key="119" class="key--fn">
                        <span>F8</span>
                    </div>
                    <div data-key="120" class="key--fn">
                        <span>F9</span>
                    </div>
                    <div data-key="121" class="key--fn">
                        <span>F10</span>
                    </div>
                    <div data-key="122" class="key--fn">
                        <span>F11</span>
                    </div>
                    <div data-key="123" class="key--fn">
                        <span>F12</span>
                    </div>
                    <div data-key="n/a" class="key--word">
                        <span>pwr</span>
                    </div>
                </div>
                <div class="keyboard__row">
                    <div class="key--double" data-key="192">
                        <div>~</div>
                        <div>`</div>
                    </div>
                    <div class="key--double" data-key="49">
                        <div>!</div>
                        <div>1</div>
                    </div>
                    <div class="key--double" data-key="50">
                        <div>@</div>
                        <div>2</div>
                    </div>
                    <div class="key--double" data-key="51">
                        <div>#</div>
                        <div>3</div>
                    </div>
                    <div class="key--double" data-key="52">
                        <div>$</div>
                        <div>4</div>
                    </div>
                    <div class="key--double" data-key="53">
                        <div>%</div>
                        <div>5</div>
                    </div>
                    <div class="key--double" data-key="54">
                        <div>^</div>
                        <div>6</div>
                    </div>
                    <div class="key--double" data-key="55">
                        <div>&</div>
                        <div>7</div>
                    </div>
                    <div class="key--double" data-key="56">
                        <div>*</div>
                        <div>8</div>
                    </div>
                    <div class="key--double" data-key="57">
                        <div>(</div>
                        <div>9</div>
                    </div>
                    <div class="key--double" data-key="48">
                        <div>)</div>
                        <div>0</div>
                    </div>
                    <div class="key--double" data-key="189">
                        <div>_</div>
                        <div>-</div>
                    </div>
                    <div class="key--double" data-key="187">
                        <div>+</div>
                        <div>=</div>
                    </div>
                    <div class="key--bottom-right key--word key--w4" data-key="8">
                        <span>delete</span>
                    </div>
                </div>
                <div class="keyboard__row">
                    <div class="key--bottom-left key--word key--w4" data-key="9">
                        <span>tab</span>
                    </div>
                    <div class="key--letter" data-char="Q">Q</div>
                    <div class="key--letter" data-char="W">W</div>
                    <div class="key--letter" data-char="E">E</div>
                    <div class="key--letter" data-char="R">R</div>
                    <div class="key--letter" data-char="T">T</div>
                    <div class="key--letter" data-char="Y">Y</div>
                    <div class="key--letter" data-char="U">U</div>
                    <div class="key--letter" data-char="I">I</div>
                    <div class="key--letter" data-char="O">O</div>
                    <div class="key--letter" data-char="P">P</div>
                    <div class="key--double" data-key="219" data-char="{[">
                        <div>{</div>
                        <div>[</div>
                    </div>
                    <div class="key--double" data-key="221" data-char="}]">
                        <div>}</div>
                        <div>]</div>
                    </div>
                    <div class="key--double" data-key="220" data-char="|\">
                        <div>|</div>
                        <div>\</div>
                    </div>
                </div>
                <div class="keyboard__row">
                    <div class="key--bottom-left key--word key--w5" data-key="20">
                        <span>caps lock</span>
                    </div>
                    <div class="key--letter" data-char="A">A</div>
                    <div class="key--letter" data-char="S">S</div>
                    <div class="key--letter" data-char="D">D</div>
                    <div class="key--letter" data-char="F">F</div>
                    <div class="key--letter" data-char="G">G</div>
                    <div class="key--letter" data-char="H">H</div>
                    <div class="key--letter" data-char="J">J</div>
                    <div class="key--letter" data-char="K">K</div>
                    <div class="key--letter" data-char="L">L</div>
                    <div class="key--double" data-key="186">
                        <div>:</div>
                        <div>;</div>
                    </div>
                    <div class="key--double" data-key="222">
                        <div>"</div>
                        <div>'</div>
                    </div>
                    <div class="key--bottom-right key--word key--w5" data-key="13">
                        <span>return</span>
                    </div>
                </div>
                <div class="keyboard__row">
                    <div class="key--bottom-left key--word key--w6" data-key="16">
                        <span>shift</span>
                    </div>
                    <div class="key--letter" data-char="Z">Z</div>
                    <div class="key--letter" data-char="X">X</div>
                    <div class="key--letter" data-char="C">C</div>
                    <div class="key--letter" data-char="V">V</div>
                    <div class="key--letter" data-char="B">B</div>
                    <div class="key--letter" data-char="N">N</div>
                    <div class="key--letter" data-char="M">M</div>
                    <div class="key--double" data-key="188">
                        <div>&lt;</div>
                        <div>,</div>
                    </div>
                    <div class="key--double" data-key="190">
                        <div>&gt;</div>
                        <div>.</div>
                    </div>
                    <div class="key--double" data-key="191">
                        <div>?</div>
                        <div>/</div>
                    </div>
                    <div class="key--bottom-right key--word key--w6" data-key="16-R">
                        <span>shift</span>
                    </div>
                </div>
                <div class="keyboard__row keyboard__row--h3">
                    <div class="key--bottom-left key--word">
                        <span>fn</span>
                    </div>
                    <div class="key--bottom-left key--word key--w1" data-key="17">
                        <span>control</span>
                    </div>
                    <div class="key--bottom-left key--word key--w1" data-key="18">
                        <span>option</span>
                    </div>
                    <div class="key--bottom-right key--word key--w3" data-key="91">
                        <span>command</span>
                    </div>
                    <div class="key--double key--right key--space" data-key="32" data-char=" ">
                        &nbsp;
                    </div>
                    <div class="key--bottom-left key--word key--w3" data-key="93-R">
                        <span>command</span>
                    </div>
                    <div class="key--bottom-left key--word key--w1" data-key="18-R">
                        <span>option</span>
                    </div>
                    <div data-key="37" class="key--arrow">
                        <span>&#9664;</span>
                    </div>
                    <div class="key--double key--arrow--tall" data-key="38">
                        <div>&#9650;</div>
                        <div>&#9660;</div>
                    </div>
                    <div data-key="39" class="key--arrow">
                        <span>&#9654;</span>
                    </div>
                </div>
            </div>
            <ShortcutsList @keyPressed="maybePressKeys($event)" @keyDepressed="dePressKeys($event)" />
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'

import ShortcutsList from '@/components/ShortcutsList.vue'


const active_shortcut = ref(null);

const is_pressing_key = ref( false );


const keyboard = ref(null)

const getKey = (e) => {
    var location = e.location;
    var selector;
    if (location === KeyboardEvent.DOM_KEY_LOCATION_RIGHT) {
        selector = ['[data-key="' + e.keyCode + '-R"]']
    } else {
        var code = e.keyCode || e.which;
        selector = [
            '[data-key="' + code + '"]',
            '[data-char*="' + encodeURIComponent(String.fromCharCode(code)) + '"]'
        ].join(',');
    }
    return document.querySelector(selector);
}

const maybePressKeys = (keys) => {
    is_pressing_key.value = true
    if (keys.length) {
        keys.forEach(key => {
            pressKey(key, true)
        });
    }
}
const dePressKeys = (keys) => {
    is_pressing_key.value = false
    if (keys.length) {
        keys.forEach(key => {
            depressKey(key)
        });
    }
}


const depressKey = (char) => {
    var key = document.querySelector('[data-char="' + char.toUpperCase() + '"]');
    if (!key) {
        key = document.querySelector('[data-key="' + char.toUpperCase() + '"]');
    }
    if (!key) {
        return console.warn('No key for', char);
    }
    key.removeAttribute('data-pressed');
}
const pressKey = (char, keep = false) => {
    var key = document.querySelector('[data-char="' + char.toUpperCase() + '"]');
    if (!key) {
        key = document.querySelector('[data-key="' + char.toUpperCase() + '"]');
    }
    if (!key) {
        return console.warn('No key for', char);
    }
    key.setAttribute('data-pressed', 'on');
    if (!keep) {
        setTimeout(function () {
            key.removeAttribute('data-pressed');
        }, 200);
    }
}


function size() {
    if (!keyboard.value) return;
    var size = keyboard.value.parentNode.clientWidth / 90;
    keyboard.value.style.fontSize = size + 'px';
    console.log(size);
}


window.addEventListener('resize', function (e) {
    size();
});

onMounted(() => {
    size();
})



</script>

<style lang="scss" scoped>


.keyboards {
    display: flex;
    // grid-template-columns: 2fr 1fr;
    padding: 40px;
    max-width: 100%;
    border-radius: 10px;
    background: black;
    gap: 20px;
    margin: 0 auto;
}


.keyboard {
    text-align: center;
    font-size: 14px;
    font-family: sans-serif;
    // border: var(--filmstro-border);
    border-radius: 15px;
    width: 100%;

    .keyboard__row {
        display: inline-block;
        height: 3em;
        margin: 0.2em;

        &>* {
            position: relative;
            background: #333;
            text-align: center;
            color: #eee;
            float: left;
            border-radius: 0.3em;
            margin: 0.2em;
            padding: 0.2em;
            width: 3.3em;
            height: 100%;
            box-sizing: border-box;
            cursor: pointer;
            -webkit-user-select: none;
            border: 1px solid #444;
            box-shadow: 0 0.2em 0 0.05em #222;
            border-bottom-color: #555;
            transition: opacity .4s ease;
        }

        [data-has-shortcut] {
            box-shadow: 0 0 0 0.04em var(--filmstro-yellow);
            &[data-key="49"] {
                box-shadow: 0 0 0 0.05em var(--filmstro-yellow);
            }

            &[data-key="50"] {
                box-shadow: 0 0 0 0.05em var(--filmstro-blue);
            }

            &[data-key="51"] {
                box-shadow: 0 0 0 0.05em var(--filmstro-red);
            }
        }

        &--h1 {
            height: 1.7em;
            line-height: 1.4em;
        }

        &--h3 {
            height: 3.3em;
        }

        &--h1>div {
            width: 3.5em;
        }


        &>div[data-pressed],
        &>div:active {
            background: #2a2a2a;
            color: #aaa;
            position: relative;
            // top: 0.2em;
            box-shadow: 0 0 0 0.05em var(--filmstro-yellow);
        }


    }
    &.is_pressing_key{
        .keyboard__row{
            &>*:not([data-pressed]){
                opacity: .6;
                box-shadow: 0 0.2em 0 0.05em #222;
            }
        }
    }


    .key--w3 {
        width: 4.6em;
    }

    .key--w4 {
        width: 6em;
    }

    .key--w5 {
        width: 6.5em;
    }

    .key--w6 {
        width: 8.3em;
    }

    .key--space {
        width: 18em;
    }

    .key--double>* {
        padding-top: 0.1em;
    }

    .key--letter {
        line-height: 2.8em;
    }



    .key--bottom-left>* {
        position: absolute;
        text-align: left;
        bottom: 0.4em;
        left: 0.4em;
    }

    .key--bottom-right>* {
        position: absolute;
        text-align: right;
        bottom: 0.4em;
        right: 0.4em;
    }

    .key--fn>* {
        font-size: 0.6em;
        line-height: 1em;
        padding-top: 1.2em;
        padding-right: 0.2em;
        text-align: right;
        float: right;
    }

    .key--word>* {
        font-size: 0.8em;
    }

    .key--arrow--tall>*,
    .key--arrow>* {
        font-size: 0.5em;
        line-height: 3em;
    }

    .key--arrow {
        height: 1.8em;
        margin-top: 1.7em;
        line-height: 0.5em;
    }

    .key--arrow--tall>* {
        padding-top: 0.2em;
    }

    .keyboard>.keyboard__row {
        text-align: center;
    }






}



.shortcuts-wrapper{
    width: 40%;
}

@media screen and (max-width:1000px) {
    .keyboards{
        flex-direction: column;
        align-items: center;
    }
}


</style>